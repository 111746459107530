.groceries-table {
    border-radius: 0.25rem;
    background: #ededed;
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
}
.groceries-table::-webkit-scrollbar {
    display: none;

}
.groceries-table table th {
    vertical-align: middle !important;
    max-width: 125px;
    overflow-wrap: normal;
    font-size: .90rem;
}
.inventory-block {
    margin: 2px;
    margin-bottom: 1px;
    width: 25px;
}
.inventory-block-container {
    border: 1px solid black;
    padding: 2px !important;
    border-radius: 2px !important;
}
.inventory-badge {
    margin: 2px;
    padding: 6px !important;
    background: rgb(201, 202, 255);
    cursor: default;
}
.view-groceries-btn {
    width: 80%;
}
.post-groceries-btn {
    width: 80%;
    margin: 0 auto;
}
.inventory-updated-span {
    font-size: 0.75rem;
    font-weight: lighter;
}