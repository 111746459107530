.load-stores {
    padding-top: 1.5rem !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
}
 .card-body {
     padding-bottom: 0px !important;
 }
.store-title {
    font-weight: bold;
    display: block;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
}
.store-hours {
    float: right;
    position: absolute;
    top: -1px;
    right: -1px;
    left: -1px;
    overflow: hidden;
    border-radius: .25rem .25rem 0px 0px !important;

}
.store-address {
    font-size: 0.6rem !important;
    display: block;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;

}
.store-img {
    border-radius: 50%;
    height: 15vw;
    width: 15vw;
    max-height: 100px;
    max-width: 100px;
    border: 1px solid black;
    text-align: center;
}
.store-rating {
    font-size: 0.75rem;
}
.separatorLine {
    width: 90%;
    height: 1px;
    background: rgba(54, 54, 54, 0.125);
    border-radius: 2px;
    position: relative;
    margin: 5px auto;
    

}
.store-header {
    display: block;
    min-height: 4vh;
    height: 100%;
}
.store-container {
    margin: 0 auto;
    margin-bottom: 1.25rem;
}
.store-container  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: 1px solid rgba(19, 19, 19, 0.623);
    border-radius: .25rem;
}


.store-img-container , .store-info-container {
    height: 100%;
}
.store-img-container {
    width: 15vw;
    max-width: 20%;
    max-height: 150px;
    float: left;
}

.store-info-container {
    width: 100%;
    max-width: 100%;
    float: right;
    text-align: center;
}

.slider-number {
    float: left;
    margin-left: 10px;
}

.setting-header {
    display: inline;
    font-weight: bolder;
}
  
.slider {
    position: relative;
    float: left;
    -webkit-appearance: none;
    width: 50%;
    border-radius: 10px;  
    background: #dbdbdb;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    background: rgb(105, 0, 119);
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: rgb(105, 0, 119);
    cursor: pointer;
}

.margin-0-auto {
    margin: 0 auto;
}
.update-btn {
    width: 50%;
    margin: 5px auto;
}

.post-modal-body {
    height: 500px;
    overflow-y: scroll;
}
.width-100 {
    width: 100%;
}
.width-80 {
    width: 80%;
}
.width-75 {
    width: 75%;
}
.width-60 {
    width: 60%;
}
.width-20 {
    width: 20%;
}
.width-15 {
    width: 15%;
}
.width-10 {
    width: 10%;
}

.settings-card{
    margin: 1rem auto;
}

.settings-header {
    margin-bottom: 1.25rem;
    font-weight: bolder;;
}